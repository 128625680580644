/**
 * @flow
 */

import * as React from 'react';
import Page from 'src/components/Page';
import ExternalLink from 'src/components/navigation/ExternalLink';
import MontDemosCoverAnimationMp4 from '../../data/mont-demos-cover-animation.mp4';
import MontDemosCoverAnimationWebm from '../../data/mont-demos-cover-animation.webm';
import {MONT_DEMOS_FFM_URL, MUSIC_OG_IMAGE} from 'src/constants';
import {linkStyle} from 'src/styles/common';

const albumSize = 400;
const styles = {
  article: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  albumCard: (theme) => ({
    ...linkStyle(theme),
    display: 'inline-block',
    width: '100%',
    maxWidth: `calc(${albumSize}px + ${2 * theme.thumbnailPadding}rem)`,
    ':hover > div': {
      background: theme.linkHoverColor,
    },
  }),
  coverWrapper: (theme) => ({
    padding: `${theme.thumbnailPadding}rem`,
  }),
  albumCover: (theme) => ({
    display: 'block',
    maxWidth: albumSize,
    maxHeight: albumSize,
    width: '100%',
    height: '100%',
    // this needs to be a string rather than number because otherwise it is interpreted as 1px
    aspectRatio: '1',
    background: '#645462',
  }),
  albumTitle: (theme) => ({
    display: 'flex',
    alignItems: 'center',
    padding: '4px 4px 0 4px',
    fontSize: `${theme.fontSizeLarge}rem`,
    fontWeight: 'normal',
  }),
};

export default function MusicPage(): React.Node {
  return (
    <Page
      title='Music'
      canonicalPath='/music/'
      ogImage={MUSIC_OG_IMAGE}
      description='Recent music highlights from artist Southerfields'
      verticallyCenterContent={true}
    >
      <article css={styles.article}>
        <ExternalLink href={MONT_DEMOS_FFM_URL} css={styles.albumCard}>
          <div css={styles.coverWrapper}>
            <video
              css={styles.albumCover}
              width={albumSize}
              height={albumSize}
              autoPlay={true}
              loop={true}
              muted={true}
              playsInline={true}
            >
              <source src={MontDemosCoverAnimationWebm} type='video/webm' />
              <source src={MontDemosCoverAnimationMp4} type='video/mp4' />
            </video>
          </div>
          <h2 css={styles.albumTitle}>mont demos</h2>
        </ExternalLink>
      </article>
    </Page>
  );
}
